import React from 'react'
import Layout from '../layouts/index'

const NotFoundPage = () => (
  <Layout>
    <div className="page">
      <div className="notfound-container">
        <h1>It doesn't look like anything to me</h1>
        <p>404: You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
